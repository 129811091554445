<template>
    <v-card
        class="mb-2"
        :class="{ 'px-5 py-4 mb-5': $vuetify.breakpoint.mdAndUp }"
    >
        <v-row class="ma-0">
            <v-col cols="12" lg="9" xl="6" class="pa-0">
                <v-card-title class="mb-4">Custom Set Up</v-card-title>
                <v-card-title class="mb-4">
                    Download Your Portal's HTML
                </v-card-title>
                <v-card-subtitle>
                    While we provide the option to download the HTML file for
                    your portal, we
                    <b>do not recommend this approach</b>
                    for several reasons:
                    <p class="mt-4">
                        <b>Manual Updates:</b>
                        Any changes or updates made to the portal design or
                        functionality will not be automatically applied to your
                        downloaded version.
                    </p>
                    <p>
                        <b>Technical Complexity:</b>
                        Hosting and maintaining your own portal requires
                        advanced technical knowledge, such as setting up a
                        server, ensuring security, and managing updates.
                    </p>
                    <p>
                        <b>Limited Support:</b>
                        We cannot provide support for portals hosted
                        independently on third-party platforms.
                    </p>
                    <p>
                        For the best experience and to receive ongoing support
                        and updates, we strongly encourage using our hosted
                        solution at clientcabin.com. However, if you still
                        prefer to proceed, you can download the HTML by clicking
                        the button below; please make sure to review the setup
                        instructions provided in the README.md file in this
                        export.
                    </p>
                    <p class="primary--text">
                        Support is limited to portals hosted within the
                        white-label domain; we will not be able to assist with
                        issues occurring on external or third-party hosted
                        platforms.
                    </p>
                    <v-btn @click="download">Download HTML</v-btn>
                </v-card-subtitle>
            </v-col>
        </v-row>
    </v-card>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class ResellerPortal extends Vue {
    download() {
        Object.assign(
            document.createElementNS('http://www.w3.org/1999/xhtml', 'a'),
            {
                target: '_blank',
                rel: 'noopener noreferrer',
                href: '/download'
            }
        ).click();
    }
}
</script>
